
//import MenuLevel2Multi from '~/components/menu_v2/MenuLevel2Multi.vue'
//import MenuLevel2MultiInsights from '~/components/menu_v2/MenuLevel2MultiInsights.vue'
import TrakkenButton from '~/components/ui/TrakkenButton.vue'
//import { SimpleSVG } from 'vue-simple-svg'
export default {
  components: {
    TrakkenButton
    //SimpleSVG,
    //MenuLevel2Multi,
    //MenuLevel2MultiInsights
  },
  props: {
    loadNav: {
      type: Boolean,
      required: true,
      default: null
    }
  },
  data() {
    return {
      toggled: false,
      svgLoaded: false,
      open: false,
      isPartiallyOnScreen: true,
      isCollapsed: false,
      level3active: 0,
      simplePage: false
    }
  },
  methods: {
    level3Change(idx) {
      this.level3active = idx
    },
    changeRoute(menuItem) {
      if (
        window.innerWidth > 992 &&
        menuItem !== undefined &&
        menuItem.level1_detail !== undefined &&
        menuItem.level1_detail[0] !== undefined &&
        menuItem.level1_detail[0].link !== undefined &&
        menuItem.level1_detail[0].link.cached_url !== undefined &&
        menuItem.level1_detail[0].link.cached_url !== ''
      ) {
        this.$router.push('/' + menuItem.level1_detail[0].link.cached_url)
      }
    },
    /* insights */
    changeRouteInsights() {
      this.$router.push('/insights')
    },
    changeRouteAi() {
      this.$router.push('/why-ai')
    },
    /* insights end */
    resetSubmenu() {
      if (window.innerWidth >= 768 && window.innerWidth < 992) {
        this.open = 1
      } else {
        this.open = false
      }
    },
    handleMouseOver(elementID) {
      if (!this.mobileMode()) this.open = elementID + 1
    },
    handleMouseLeave() {
      if (!this.mobileMode()) this.open = false
    },
    mobileMode() {
      if (process.client && window.innerWidth < 768) return true
      return false
    }
  },
  watch: {
    $route(to) {
      this.open = false
      if (this.$refs.burgerNav.show) {
        this.$refs.burgerNav.toggle()
      }
      if (
        to.path.includes('/imprint') ||
        to.path.includes('/data-privacy-policy') ||
        to.path.includes('/cookie-settings')
      ) {
        this.simplePage = true
      } else {
        this.simplePage = false
      }
    },
    isCollapsed: function (newValue, oldValue) {
      if (newValue) {
        document.querySelector('body').classList.add('no-scroll')
      } else if (oldValue) {
        document.querySelector('body').classList.remove('no-scroll')
      }
    }
  },
  computed: {
    logo() {
      return this.$store.state.menus.main_nav !== undefined &&
        this.$store.state.menus.main_nav &&
        this.$store.state.menus.main_nav.logo &&
        this.$store.state.menus.main_nav.logo[0].logo
        ? this.$store.state.menus.main_nav.logo[0].logo
        : null
    },
    contactButton() {
      return this.$store.state.menus.main_nav &&
        typeof this.$store.state.menus.main_nav.button !== 'undefined' &&
        this.$store.state.menus.main_nav.button[0]
        ? this.$store.state.menus.main_nav.button[0]
        : null
    },
    firstLevelMenuPoints() {
      return this.collectedMenuPoints.map((menuItem) => {
        return {
          description:
            menuItem &&
            menuItem !== undefined &&
            menuItem.level1_detail !== undefined &&
            menuItem.level1_detail[0] !== undefined &&
            menuItem.level1_detail[0].description !== undefined &&
            menuItem.level1_detail[0].description[0] !== undefined &&
            menuItem.level1_detail[0].description[0].text !== undefined
              ? menuItem.level1_detail[0].description[0].text
              : null,
          title:
            menuItem &&
            menuItem !== undefined &&
            menuItem.level1_detail !== undefined &&
            menuItem.level1_detail[0] !== undefined &&
            menuItem.level1_detail[0].level1 !== undefined &&
            menuItem.level1_detail[0].level1[0] !== undefined &&
            menuItem.level1_detail[0].level1[0].text !== undefined
              ? menuItem.level1_detail[0].level1[0].text
              : null,
          main_url:
            menuItem &&
            menuItem !== undefined &&
            menuItem.level1_detail !== undefined &&
            menuItem.level1_detail[0] !== undefined &&
            menuItem.level1_detail[0].link !== undefined &&
            menuItem.level1_detail[0].link.cached_url !== undefined
              ? menuItem.level1_detail[0].link.cached_url
              : null
        }
      })
    },
    tabletMode() {
      if (process.client) {
        if (window.innerWidth >= 768 && window.innerWidth <= 992) return true
      }
      return false
    },
    checkTransparent() {
      if (!this.simplePage) {
        if (this.tabletMode && this.isPartiallyOnScreen && !this.toggled) {
          return true
        } else if (this.isPartiallyOnScreen && !this.open && !this.toggled) {
          return true
        }
      }
      return false
    },
    collectedMenuPoints() {
      /* console.log(
        'collected menus from store in top header',
        this.$store.state.menus.main_nav._uid
      ) */
      return [
        this.$store.state.menus &&
        this.$store.state.menus.main_nav &&
        this.$store.state.menus.main_nav.services_and_solutions &&
        this.$store.state.menus.main_nav.services_and_solutions[0]
          ? this.$store.state.menus.main_nav.services_and_solutions[0]
          : null,
        this.$store.state.menus &&
        this.$store.state.menus.main_nav &&
        this.$store.state.menus.main_nav.products_and_tools &&
        this.$store.state.menus.main_nav.products_and_tools[0]
          ? this.$store.state.menus.main_nav.products_and_tools[0]
          : null,
        this.$store.state.menus &&
        this.$store.state.menus.main_nav &&
        this.$store.state.menus.main_nav.company &&
        this.$store.state.menus.main_nav.company[0]
          ? this.$store.state.menus.main_nav.company[0]
          : null
      ]
    },
    insightsMenu() {
      return [
        this.$store.state.menus &&
        this.$store.state.menus.main_nav &&
        this.$store.state.menus.main_nav.insights &&
        this.$store.state.menus.main_nav.insights[0]
          ? this.$store.state.menus.main_nav.insights[0]
          : null
      ]
    },
    whyAiMenu() {
      return [
        this.$store.state.menus &&
        this.$store.state.menus.main_nav &&
        this.$store.state.menus.main_nav.why_ai &&
        this.$store.state.menus.main_nav.why_ai[0]
          ? this.$store.state.menus.main_nav.why_ai[0]
          : null
      ]
    }
  },
  updated() {
    if (process.client) {
      window.dispatchEvent(new Event('scroll'))
    }
  },
  mounted() {
    /* console.log(
      'mounted header store check',
      this.$store.state.menus.main_nav._uid
    ) */
    /* window.checkStore = () => {
      console.log(this.$store.state.menus.main_nav)
    } */
    this.$watch(
      () => {
        if (this.$refs.burgerNav !== undefined) {
          return this.$refs.burgerNav.show
        }
      },
      (val) => {
        this.toggled = val
      }
    )
    if (process.client) {
      window.addEventListener('scroll', () => {
        if (
          document.body.scrollTop > 60 ||
          document.documentElement.scrollTop > 60
        ) {
          this.isPartiallyOnScreen = false
        } else {
          this.isPartiallyOnScreen = true
        }
      })
      if (
        this.$route.path.includes('/imprint') ||
        this.$route.path.includes('/data-privacy-policy') ||
        this.$route.path.includes('/gcp-terms') ||
        this.$route.path.includes('/cookie-settings')
      ) {
        this.simplePage = true
      }
    }
  }
}
